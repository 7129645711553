<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Whitelist
        </h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button variant="primary" @click="doSave">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-form-group label="Domains">
      <b-form-tags v-model="savedDomains"></b-form-tags>
    </b-form-group>
  </b-overlay>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      savedDomains: [],
    };
  },
  async created() {
    this.isLoading = false;
    await this.fetchAll();
    this.savedDomains = this.domains;
  },
  computed: {
    domains() {
      return this.$store.state.Whitelist.domains;
    },
  },
  methods: {
    async fetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Whitelist/fetchAll");
      this.isLoading = false;
    },
    async doSave() {
      this.isLoading = true;
      await this.$store.dispatch("Whitelist/update", this.savedDomains);
      this.$root.$bvToast.toast("Whitelist saved.", { variant: "success" });
      this.isLoading = false;
    },
  },
};
</script>
